import { Helmet } from 'react-helmet';

function NotFound() {
  return (
      <div>
          <Helmet>
              <meta httpEquiv="status" content="404" />
              <meta property="og:title" content="Сушим&Вялим — Мясные чипсы" />
              <meta property="og:site_name" content="Сушим&Вялим — Мясные чипсы" />
              <meta property="og:description" content="Мясные чипсы, джерки, натуральные продукты, сушеное мясо, здоровое питание" />
              <meta property="og:url" content="https://vyalim.ru/" />
              <meta property="og:type" content="website" />
              <meta property="og:image" content="https://vyalim.ru/static/media/logo%20full%202.e7e9170948a57ee17355.png" />
              <meta property="og:locale" content="ru_RU" />
              <title>404 - Страница не найдена</title>
          </Helmet>
          <h1>404 - Страница не найдена</h1>
          <p>Извините, страница не существует.</p>
      </div>
  );
}

export default NotFound;
